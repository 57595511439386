<template>
	<div>
		<div class="logo" v-if="isLogoShow">
			<img :src="webSiteObj.logo" alt="" v-if="webSiteObj">
		</div>
		<div class="login">
			<div class="loginTop">
				<!-- <div @click="listEvt(it.id)"
             v-for="(it,i) in loginList"
             :key="i"
             :class="it.id === index ? 'loginActive' : ''">{{it.name}}</div> -->
				<div style="text-align: enter" v-text="isLogoShow ? '用户登陆' :'账号密码登录'"></div>
			</div>

			<!-- <div class="phone"
           v-if="index === 0">
        <div class="phoneInput">
          <el-input placeholder="请输入手机号"
                    v-model="form.phone" pattern="^A[A-z]{2}"></el-input>
          <img src="../../assets/login/phone.png"
               alt="">
        </div>
        <div class="yzm">
          <el-input placeholder="请输入短信验证码"
                    v-model="form.code"></el-input>
          <div @click="getCode()">
            <el-button :disabled="btnFlag">{{ result > 0 && result ? time2: "获取验证码" }}</el-button>
          </div>
          <img src="../../assets/login/yzm.png"
               alt="">
        </div>

        <div class="loginCls">
          <el-button @click="loginPhoneEvt">登录</el-button>
          <div @click="$router.push('/register')">注册</div>
        </div>
      </div> -->

			<div class="phone">
				<div class="phoneInput">
					<el-input placeholder="请输入手机号" v-model="form.user"></el-input>
					<img src="../../assets/login/phone.png" alt="">
				</div>
				<div class="yzm">
					<el-input style="width:100%" placeholder="请输入密码" v-model="form.password" type="password"></el-input>
					<img src="../../assets/login/yzm.png" alt="">
				</div>
				<div class="forgetPsd" @click="$router.push('/retrievePassword')">忘记密码？</div>
				<div class="loginCls" style="margin-top:15px">
					<el-button @click="loginUserEvt" @keyup.enter="loginUserEvt">登录</el-button>
					<el-button @click="$router.push('/register')" style="margin-left:0px;margin-top:10px">注册</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	import md5 from 'js-md5'
	import test from '../../utils/test.js'
	export default {
		data() {
			return {
				isLogoShow: false,
				index: 0,
				loginList: [{
						name: '手机账号登录',
						id: 0
					},
					{
						name: '账号密码登录',
						id: 1
					},
				],
				rules: {
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: (rule, value, callback) => {
								if (!test.mobile(value)) {
									callback(new Error("手机号格式错误"));
								} else {
									callback();
								}
							}
						}
					]
				},
				btnFlag: false,
				timer: null,
				result: null, //此变量为截止时间戳与当前时间戳相减的差
				timeStamp: null, //此变量为倒计时截止的时间戳
				time2: null,
				arr: [],
				form: {
					phone: '',
					code: '',
					user: '',
					password: ''
				},
				// 是否登录
				isLogin: false
			}
		},
		created() {
			// 如果在登录页就显示logo
			if (this.$route.path.indexOf('login') === 1) {
				this.isLogoShow = true
			}
		},
		watch: {
			'$route'(to, from) {
				this.$router.go(0);
			}
		},
		computed: {
			...mapState(['webSiteObj', 'menuList']),
		},
		mounted() {
			// 绑定监听事件
			window.addEventListener("keydown", this.keyDown);
		},
		destroyed() {
			// 销毁事件
			window.removeEventListener("keydown", this.keyDown, false);
		},
		methods: {
			// 点击回车键登录
			keyDown(e) {
				// 回车则执行登录方法 enter键的ASCII是13
				if (e.keyCode === 13) {
					this.loginUserEvt(); // 定义的登录方法
				}
			},
			listEvt(id) {
				this.index = id
			},
			//电话验证码登录
			// loginPhoneEvt() {
			// 	const reg = /^1[3|4|5|7|8][0-9]{9}$/
			// 	if (this.form.phone && reg.test(this.form.phone)) {
			// 		if (this.form.code) {
			// 			this.$http.post('loginApi', {
			// 				type: 2,
			// 				telephone: this.form.phone,
			// 				code: this.form.code
			// 			}).then(res => {
			// 				if (res.code === 200) {
			// 					this.$message.success('登录成功！')
			// 					localStorage.setItem('token', res.data.token)
			// 					localStorage.setItem('userInfo', JSON.stringify(res.data.stu))
			// 					if (this.isLogoShow) {
			// 						if (this.menuList[0].content == 8) {
			// 							window.location.href = this.menuList[0].jumpUrl
			// 						} else {
			// 							this.$router.push({
			// 								path: '/',
			// 								query: {
			// 									isUpdate: 1,
			// 									index: 1
			// 								}
			// 							})
			// 						}
			// 					} else {
			// 						this.$message.success('登录成功！')
			// 						this.$router.push({
			// 							path: '/',
			// 							query: {
			// 								isUpdate: 1,
			// 								index: 1
			// 							}
			// 						})
			// 						localStorage.setItem('token', res.data.token)
			// 						localStorage.setItem('userInfo', JSON.stringify(res.data.stu))
			// 					}
			// 					// if (res.data.isUpdate === 0) {
			// 					// 	this.$router.push({
			// 					// 		path: '/',
			// 					// 		query: {
			// 					// 			isUpdate: 0,
			// 					// 			index: 1
			// 					// 		}
			// 					// 	})
			// 					// 	this.$message.error('密码过于简单，请修改')
			// 					// } else {
			// 					// 	console.log('登录成功');
			// 					// 	this.$message.success('登录成功！')

			// 					// 	this.$router.push({
			// 					// 		path: '/',
			// 					// 		query: {
			// 					// 			isUpdate: 1,
			// 					// 			index: 1
			// 					// 		}
			// 					// 	})

			// 					// 	localStorage.setItem('token', res.data.token)
			// 					// 	localStorage.setItem('userInfo', JSON.stringify(res.data.stu))
			// 					// }

			// 				} else {
			// 					this.$message.error(res.message)
			// 				}
			// 			})
			// 		} else {
			// 			this.$message.error('请输入电话验证码')
			// 		}
			// 	} else {
			// 		this.$message.error('手机号码格式不正确')
			// 	}
			// },
			//账号密码登录
			loginUserEvt() {
				if (!test.mobile(this.form.user)) return this.$message.error('手机号码格式不正确')
				if (!this.form.password) return this.$message.error('请输入密码')
				this.$store.dispatch('Login', {
					type: 1,
					loginName: this.form.user,
					passWord: md5(this.form.password)
				}).then((result) => {
					if (!result) return this.form.password = ''
					this.$store.dispatch('getAvatar')
					if (this.isLogoShow) { // 在登录页
						if (this.menuList[0].content == 8) {
							window.location.href = this.menuList[0].jumpUrl
						} else {
							if(result.isUpdate === 1){
								this.$router.push({
								path: '/',
								query: {
									isUpdate: 1,
									index: 1
								}
							})
							}else{
								localStorage.setItem('isUpdate','true')
								this.$message.error('第一次进入，请修改个人资料')
								this.$router.push({
								path: '/personalCenter',
								query: {
									isUpdate: 0
								}
							})
							}
						}
					} else {//在首页
						if(result.isUpdate === 1){
								this.$router.push({
								path: '/',
								query: {
									isUpdate: 1,
									index: 1
								}
							})
							}else{
								localStorage.setItem('isUpdate','true')
								this.$message.error('第一次进入，请修改个人资料')
								this.$router.push({
								path: '/personalCenter',
								query: {
									isUpdate: 0
								}
							})
							}
					}
				})
			},
			//倒计时时间
			async countTime() {
				//如果result<=0,证明上一次读秒已结束，需要重新设置新的时间戳
				if (!this.result) {
					let currentStamp = new Date().getTime();
					this.timeStamp = new Date().getTime() + 300 * 200; //设置倒计时时间
					this.result = (this.timeStamp - currentStamp) / 1000;
					//  this.time2 =  this.formatSeconds(this.result)
					//  this.result--;
				}
				setInterval(this.TimeFirst(), 10)
				this.timer = setInterval(() => {
					this.time2 = this.formatSeconds(this.result)
					this.result--;
					if (this.result === 0) {
						clearInterval(this.timer);
						this.timer = null;
						this.btnFlag = false;
					}
				}, 1000);
			},
			TimeFirst() {
				this.time2 = this.formatSeconds(this.result)
				this.result--;
			},
			//获取验证码
			async getCode() {
				if (!this.form.phone || !test.mobile(this.form.phone)) {
					this.$message.error('请输入正确的手机号码')
				} else {
					if (!this.btnFlag) {
						if (this.form.phone) {
							this.btnFlag = true;
							await this.countTime();
							await this.$http.post('getCodeApi', {
								phoneNumber: this.form.phone
							}).then(res => {
								if (res.code === 200) {
									this.form.code = res.data
									this.$message.success('验证码已发送')
								}
							})
						} else {
							this.$message.error('请先输入电话号码！')
						}
					}
				}
			},
			//时间转换时分秒
			formatSeconds(value) {
				let result = parseInt(value)
				let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result /
					60 % 60));
				let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

				let res = '';
				if (h !== '00') res += `${h}时`;
				if (m !== '00') res += `${m}分`;
				res += `${s}秒`;
				return res;
			}
		}
	}
</script>

<style lang="less" scoped>
	.logo {
		width: 1200px;
		margin: auto;

		img {
			width: 650px;
			height: 80px;
			margin-top: 10px
		}
	}

	.login {
		width: 394px;
		height: 405px;
		background: #FFFFFF;
		box-shadow: 0px 4px 38px 0px rgba(53, 53, 53, 0.19);
		border-radius: 10px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		padding: 21px 46px;

		.loginTop {
			// display: flex;
			// justify-content: space-between;
			text-align: center;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #666;
			margin-bottom: 40px;

			div {
				cursor: pointer;
			}
		}

		.loginActive {
			color: #000000;
		}

		.phone {
			.phoneInput {
				position: relative;

				/deep/.el-input__inner {
					padding: 0 30px;
				}

				img {
					position: absolute;
					width: 12px;
					height: 15px;
					left: 10px;
					top: 12px;
				}
			}

			.forgetPsd {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
				text-align: right;
				margin-top: 30px;
				cursor: pointer;
			}

			.yzm {
				/deep/.el-input__inner {
					font-size: 14px;
					padding: 0 15px 0 30px !important;
				}

				margin-top: 34px;
				display: flex;
				position: relative;

				/deep/.el-input {
					width: 196px;
					height: 41px;
					border-radius: 5px 0px 0px 5px;

					/deep/.el-input__inner {
						padding: 0 30px;
					}
				}

				img {
					position: absolute;
					width: 12px;
					height: 15px;
					left: 10px;
					top: 12px;
				}

				div {
					width: 108px;
					height: 40px;
					border-radius: 0px 5px 5px 0px;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #010101;
					text-align: center;
					// line-height: 38px;
					cursor: pointer;

					button {
						width: 108px;
						height: 40px;
						background: #FFFFFF;
					}
				}
			}

			.loginCls {
				margin-top: 62px;

				.el-button {
					width: 100%;
					background: #E51E0B;
					border-radius: 5px;
					color: #FFFFFF;
				}

				div {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #E51E0B;
					margin-top: 25px;
					cursor: pointer;
				}
			}
		}
	}
</style>
